<template>
	<div class="page">

		<el-tabs type="border-card">
			<el-tab-pane label="公众号">
				<fieldset class="search-box">
					<legend>查询条件</legend>
					<el-input size="small" style="width: 300px;margin-right: 20px;" placeholder="请输入公众号"
						v-model="input1">
						<template #prepend>公众号</template>
					</el-input>
					<el-button size="small">搜索</el-button>
					<el-button @click="openAddWin" type="primary" size="small">添加公众号</el-button>
				</fieldset>

				<div ref="tableBox"
					style="margin-top: 10px;flex-grow: 1; background-color: white;padding-bottom: 20px;">
					<el-table size="small" header-cell-class-name="tableHeader" :data="listData" border
						:max-height="tableHeight" style="width: 100%;">
						<el-table-column fixed prop="beizhu" label="公众号" width="200">
						</el-table-column>
						<el-table-column prop="client_id" label="clientId" width="100">
						</el-table-column>
						<el-table-column fixed="right" label="操作">
							<template #default="scope">
								<el-button @click="pay(scope.row)" type="text" size="small">支付检测</el-button>
								<el-button @click="wxTest(scope.row)" type="text" size="small">公众号报警检测</el-button>
								<el-button @click="phoneTestFun(scope.row)" type="text" size="small">阿里云电话报警检测
								</el-button>
								<el-button @click="phoneTestTxFun(scope.row)" type="text" size="small">腾讯云电话报警检测
								</el-button>
								<el-button @click="smsTestFun(scope.row)" type="text" size="small">腾讯云短信报警检测</el-button>
								<el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
								<el-button @click="deleteDevice(scope.row)" type="text" size="small">删除</el-button>
							</template>
						</el-table-column>
					</el-table>

					<!-- <div style="margin-top: 20px; text-align: right;">
						<el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
					</div> -->
				</div>
			</el-tab-pane>
			<el-tab-pane label="商户">
				<merchantModel></merchantModel>
			</el-tab-pane>
		</el-tabs>

		<el-dialog v-model="addDialog" width="70%" top="2vh">
			<template #title>
				<span>
					<span>{{title}}</span>
					<el-button @click="addData = sl_data" style="margin-left: 30px;" type="text">填充示例数据</el-button>
					<el-button @click="openWin('https://mp.weixin.qq.com/')" style="margin-left: 30px;" type="text">
						微信公众平台</el-button>
					<el-button @click="openWin('https://www.aliyun.com/')" style="margin-left: 30px;" type="text">阿里云
					</el-button>
					<el-button @click="openWin('https://cloud.tencent.com/')" style="margin-left: 30px;" type="text">腾讯云
					</el-button>
					<el-button style="margin-left: 30px;" type="text">配置获取教程</el-button>
				</span>
			</template>
			<el-tabs type="border-card">
				<el-tab-pane label="公众号配置">
					<el-form size="small" label-position="left" label-width="200px" :model="addData">
						<el-form-item label="公众号名称">
							<el-input v-model="addData.beizhu"></el-input>
						</el-form-item>
						<el-form-item label="客户端标识">
							<el-input v-model="addData.client_id"></el-input>
						</el-form-item>
						<el-form-item label="公众号id(app_id)">
							<el-input v-model="addData.app_id"></el-input>
						</el-form-item>
						<el-form-item label="开发者密钥(app_secret)">
							<el-input v-model="addData.app_secret"></el-input>
						</el-form-item>
						<el-form-item label="消息模板">
							<el-input v-model="addData.wx_moban"></el-input>
						</el-form-item>
						<el-form-item label="token获取地址">
							<el-input v-model="addData.token_url"></el-input>
						</el-form-item>
						<el-form-item label="txt文件内容">
							<el-input v-model="addData.wx_file"></el-input>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="商户配置">
					<el-form size="small" label-position="left" label-width="200px" :model="addData">
						<el-form-item label="话费商户">
							<el-select v-model="addData.huaFei_merchant_id" placeholder="选择商户">
								<el-option v-for="item in selectData" :key="item.value" :label="item.title"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="年费商户">
							<el-select v-model="addData.nianFei_merchant_id" placeholder="选择商户">
								<el-option v-for="item in selectData" :key="item.value" :label="item.title"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="腾讯云配置">
					<el-button @click="txDL" type="text">代理配置</el-button>
					<el-form size="small" label-position="left" label-width="200px" :model="addData">
						<el-form-item label="语音消息APPID">
							<el-input v-model="addData.phone_appId"></el-input>
						</el-form-item>
						<el-form-item label="语音消息KEY">
							<el-input v-model="addData.phone_key"></el-input>
						</el-form-item>
						<el-form-item label="短信APPID">
							<el-input v-model="addData.sms_appId"></el-input>
						</el-form-item>
						<el-form-item label="短信KEY">
							<el-input v-model="addData.sms_key"></el-input>
						</el-form-item>
						<!-- 						<el-form-item label="电话模板">
							<el-input v-model="addData.phone_moban"></el-input>
						</el-form-item> -->
						<el-form-item label="短信模板">
							<el-input v-model="addData.sms_moban"></el-input>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="阿里云配置">
					<el-button type="text">代理配置</el-button>
					<el-form size="small" label-position="left" label-width="200px" :model="addData">
						<el-form-item label="accessKeyId">
							<el-input v-model="addData.aly_accessKeyId"></el-input>
						</el-form-item>
						<el-form-item label="accessKeySecret">
							<el-input v-model="addData.aly_accessKeySecret"></el-input>
						</el-form-item>
						<el-form-item label="电话模板">
							<el-input v-model="addData.aly_phone_moban"></el-input>
						</el-form-item>
						<el-form-item label="被叫显号">
							<el-input v-model="addData.aly_num"></el-input>
						</el-form-item>
						<el-divider>无特殊情况下，下面可采用默认值</el-divider>
						<el-form-item label="product">
							<el-input v-model="addData.aly_product"></el-input>
						</el-form-item>
						<el-form-item label="domain">
							<el-input v-model="addData.aly_domain"></el-input>
						</el-form-item>
						<el-form-item label="endpoint">
							<el-input v-model="addData.aly_endpoint"></el-input>
						</el-form-item>
					</el-form>
				</el-tab-pane>
			</el-tabs>

			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button v-if="addBtn" @click="addItem" type="primary">添加</el-button>
					<el-button v-if="editBtn" @click="upDateItem" type="primary">更新</el-button>
				</span>
			</template>
		</el-dialog>

	</div>
</template>

<script>
	import {
		add,
		list,
		payTest,
		update,
		wxBaoJingTest,
		phoneTest,
		phoneTestTx,
		smsTest,
		getWxMerchantList
	} from '../../api/merchant.js'
	import {
		ElNotification,
		ElLoading,
		ElMessageBox,
	} from 'element-plus'
	import {
		onMounted,
		reactive
	} from 'vue'
	import merchantModel from './merchantModel'
	export default {
		components: {
			merchantModel
		},

		data() {
			return {
				title: "",
				addBtn: false,
				editBtn: false,
				nowItem: {},
				sh: "",
				showInfo: false,
				addDialog: false,
				addData: {
					aly_product: "Dyvmsapi",
					aly_domain: "dyvmsapi.aliyuncs.com",
					aly_endpoint: "http://oss-cn-hangzhou.aliyuncs.com",
				},
				sl_data: {
					aly_accessKeyId: "LTAIIDbaCTenTHeF",
					aly_accessKeySecret: "gey9LECm3N0fBs9UKrzBd6xlrr9MNY",
					aly_domain: "dyvmsapi.aliyuncs.com",
					aly_endpoint: "http://oss-cn-hangzhou.aliyuncs.com",
					aly_num: "051068109968",
					aly_phone_moban: "TTS_171855146",
					aly_product: "Dyvmsapi",
					api_v3: "wangpeng2021wangpeng2021wangpeng",
					app_id: "wx51192dbf103b1c04",
					app_secret: "wangpeng2021wangpeng2021wangpeng",
					beizhu: "测试",
					client_id: "3",
					pay_cert: "-----BEGIN CERTIFICATE-----\nMIID8DCCAtigAwIBAgIUbRe9yJ0cIHkHr3f9Oev6OtvPV8wwDQYJKoZIhvcNAQEL\nBQAwXjELMAkGA1UEBhMCQ04xEzARBgNVBAoTClRlbnBheS5jb20xHTAbBgNVBAsT\nFFRlbnBheS5jb20gQ0EgQ2VudGVyMRswGQYDVQQDExJUZW5wYXkuY29tIFJvb3Qg\nQ0EwHhcNMjEwNjI2MTIzNTE4WhcNMjYwNjI1MTIzNTE4WjCBgTETMBEGA1UEAwwK\nMTUyNTYyMjg0MTEbMBkGA1UECgwS5b6u5L+h5ZWG5oi357O757ufMS0wKwYDVQQL\nDCTmtY7ljZflpKfmtqbnlLXlrZDmioDmnK/mnInpmZDlhazlj7gxCzAJBgNVBAYM\nAkNOMREwDwYDVQQHDAhTaGVuWmhlbjCCASIwDQYJKoZIhvcNAQEBBQADggEPADCC\nAQoCggEBAO4DALP/3o0gfUw1aNAbuvF9r0WXeBomXRcSKpwDh1TamfxaM4JpCqRu\n0Io4Zwli3M001E33QaX75Kh4Y6tPuNUXydbdmxWsPteujWtJe8UICl0/orRWZGQu\nU97QVQI/GuOWTKfTEtWt11nscgV9yx8rSlFqyRGSsrq1NuYxv7s3g5w40XdOCsEk\n1wphjnCM3VYVM5MGLAEny7gDpgVEQchmRqF+KFpcDLlfjSeWgcz4RkjfCmHPlmq9\nd11V+ct1GYMmCtxAwF0gpK25WKKxGABQRBhIoukEv2vK72B2CAjNP8aDzAJnhv2x\nZleGHjN8lT1lucXn50Y7qt5Jq6q+pscCAwEAAaOBgTB/MAkGA1UdEwQCMAAwCwYD\nVR0PBAQDAgTwMGUGA1UdHwReMFwwWqBYoFaGVGh0dHA6Ly9ldmNhLml0cnVzLmNv\nbS5jbi9wdWJsaWMvaXRydXNjcmw/Q0E9MUJENDIyMEU1MERCQzA0QjA2QUQzOTc1\nNDk4NDZDMDFDM0U4RUJEMjANBgkqhkiG9w0BAQsFAAOCAQEAm5x9lRJji3B72aT6\n8kYm9pK0Z/J6feRtK4xdmipcdpBEy5PMxAC58ABN6yFG8aLNiX9Kd2vqpTw6fl/s\nc3In/VN5VF2NsJTIpbIwpppxOZDrGuMg+XZnkI7X7riHXZASo/KU9oaEZ0waht8A\nsxx1YRoL2pIlbxxlGFOyJ8NcSQblvZjrglvXEyB3pwtBOI3u0nPciKBFL7tHGWSW\nuhUagWq5cLprTnTA3uAd7rMcWAb0T/bRg/qrdbzl4bREsq8+Mmd1vZs4j0+z1TG1\nI07IOSbvwTCYrZh56g869kHNs/Dv5+4jOJboSqpo0zMQK1A/UlE8qMhDzoWJ1rcE\nW4G1Zg==\n-----END CERTIFICATE-----\n",
					pay_key: "-----BEGIN PRIVATE KEY-----\nMIIEvwIBADANBgkqhkiG9w0BAQEFAASCBKkwggSlAgEAAoIBAQDuAwCz/96NIH1M\nNWjQG7rxfa9Fl3gaJl0XEiqcA4dU2pn8WjOCaQqkbtCKOGcJYtzNNNRN90Gl++So\neGOrT7jVF8nW3ZsVrD7Xro1rSXvFCApdP6K0VmRkLlPe0FUCPxrjlkyn0xLVrddZ\n7HIFfcsfK0pRaskRkrK6tTbmMb+7N4OcONF3TgrBJNcKYY5wjN1WFTOTBiwBJ8u4\nA6YFREHIZkahfihaXAy5X40nloHM+EZI3wphz5ZqvXddVfnLdRmDJgrcQMBdIKSt\nuViisRgAUEQYSKLpBL9ryu9gdggIzT/Gg8wCZ4b9sWZXhh4zfJU9ZbnF5+dGO6re\nSauqvqbHAgMBAAECggEBAIWNfcmC27hI8MXgek28Iav8IGtmUOgrerTFZs9gU4HT\nn/AwFRMqKeSWocHNYFOt+8PRzwdopJekvmuiEhtAgrBfxswgUTNaEXEo6wW2mBK5\nLkegu4WBrJdiP4GZWPoHuujVn3HUSI9KGrrEqD5Yla+JtQv2ZZjMvilyIboNVE6S\nBOwJXKu0hYCv0e9YpeNhJhFauU0bZtrX8gsFauyZIJ+eeJrQhx9aK6Xb9QAa+ySh\nMK8/pv4P1Fndw16Ubj7z6aMkRZ+suR7a38X3u0s1tlOcF17q1EnAmlTVDMw9xGD8\nTVT5WLS/aeN+mNV59vApW2W6aw7pJwiPEogJ346g0uECgYEA99tzD28S8IlyvsbL\nsr6Ox/xXCn6JhCPxa56Ge13LOjedkWI+H+hxRJ83eMZNa8zyUqDqBMZdRfItR/1R\ncb77XapKeBOgTLdXn8CnUXgO0VDUzm0qcaMPgJLWf0RkRzTgvT2zgatkg7onZDlM\nwzEp5HQTJ+GnUP9aGEjT3GbaZt8CgYEA9dS/9gplAHrLdTueEGg4zM8QvxYY21XV\nrMCRN0g52lpPYejg7R1+YpOJ+rSvBUR6z7CyxnDrm4uUwdBD7jSH0dLw7P2Lk2ib\nrpWcPt2MwSt2bTlhauyC3lL3IKuuD6PX6m6XlbCay6nXTkolKhjcsn3KzWql/NzX\n//76mE7fxRkCgYEA7GXfISpUixzdVz4uTGZZsj5oGjdEG8dYXMfPBl0YF4ex/lcQ\nX8iIN+YPt16Tuyy3Nr4JVdKIZc0tCQuAIZgBEX35FvvJGUKJDXJnY1m58ERDJDuU\njtvyTjQ+5xWo+w1hCL2Pty5saQMkzP+lykna5e0b1B7yQVu7Z4wWnZFv30sCgYBx\nd/Pq8z1PjV5gDFaXKCQflOwb520twF7SUEg3BiV9/fVqMf2wNM7jXUpw5/lpKwSs\n3NiH9BMsRq8xDppsVt7iIMWH/jfwoHZLjU8DTWvstc0B+VxX6Dc3u3/KGrRLD8tX\nsBhTxaKNoyV4Q40H81XbJWj4i+N15dHlnaG5r55l8QKBgQCsDLRXF1abw1KGWqS3\njXN1fJT8cuA1K4mPCnfzrXbmZ0h1QZVoWaUMJDrXBYj9/TKZ+f2SxMZsDuwhXhmd\n28AYhvA2KgRv/lOxT75qVMrDPUdAG67xCMUTlvLIhWYjYIXy2NgROZBoH1WsLGGj\n5ObSJciRQsJcByDp6njNY6iPoQ==\n-----END PRIVATE KEY-----\n",
					pay_mchid: "1525622841",
					pay_serial_no: "6D17BDC89D1C207907AF77FD39EBFA3ADBCF57CC",
					phone_appId: "1400434105",
					phone_key: "abe0d1e657a1d2281fd2e77de40a34ae",
					phone_moban: "1612231",
					sms_appId: "1400045604",
					sms_key: "827cb80b5a542779ee880aec73536880",
					sms_moban: "1089083",
					token_url: "http://www.darundianzi.cn/15AR.outtoken.php",
					wx_file: "itl89GpUPEvQwpI6",
					wx_moban: "2MszLocBPb5mSr64t_uBKBfPgnhuTEIJaDt7s-I5Up4",
				}
			}
		},

		setup() {
			let listData = reactive([])
			let selectData = reactive([])
			let limit = 100;
			let startPage = 0;
			onMounted(() => {
				list(startPage, limit).then(res => {
					listData.push(...res.data.data)
				})
				
				getWxMerchantList(0).then(res=>{
					selectData.push(...res.data.data)
				})
			})
			return {
				listData,
				limit,
				startPage,
				selectData
			}
		},

		methods: {
			handleClick: function(item) {
				this.addDialog = true
				this.addData = item
				this.editBtn = true
				this.addBtn = false
				this.title = "编辑用户"
			},

			wxTest: function(data) {
				ElMessageBox.prompt('请输入openid（ 请登录相关微信公众号手动查询 ）', '公众号报警测试', {
						confirmButtonText: '提交',
						cancelButtonText: '取消',
					})
					.then(({
						value
					}) => {
						wxBaoJingTest(data.client_id, value).then(() => {
							ElNotification({
								title: '成功',
								message: '已发送，注意查收！',
								type: 'success',
							})
						}).catch(() => {
							ElNotification({
								title: '错误',
								message: '服务器错误！',
								type: 'error',
							})
						})
					})
			},

			phoneTestFun: function(data) {
				ElMessageBox.prompt('请输入手机号测试', '公众号报警测试', {
						confirmButtonText: '提交',
						cancelButtonText: '取消',
					})
					.then(({
						value
					}) => {
						phoneTest(value, data.client_id).then(() => {
							ElNotification({
								title: '成功',
								message: '已发送，注意接听！',
								type: 'success',
							})
						}).catch(() => {
							ElNotification({
								title: '错误',
								message: '服务器错误！',
								type: 'error',
							})
						})
					})
			},

			phoneTestTxFun: function(data) {
				ElMessageBox.prompt('请输入手机号测试', '公众号报警测试', {
						confirmButtonText: '提交',
						cancelButtonText: '取消',
					})
					.then(({
						value
					}) => {
						phoneTestTx(value, data.client_id).then(() => {
							ElNotification({
								title: '成功',
								message: '已发送，注意接听！',
								type: 'success',
							})
						}).catch(() => {
							ElNotification({
								title: '错误',
								message: '服务器错误！',
								type: 'error',
							})
						})
					})
			},

			smsTestFun: function(data) {
				ElMessageBox.prompt('请输入手机号测试', '公众号报警测试', {
						confirmButtonText: '提交',
						cancelButtonText: '取消',
					})
					.then(({
						value
					}) => {
						smsTest(value, data.client_id).then(() => {
							ElNotification({
								title: '成功',
								message: '已发送，注意接听！',
								type: 'success',
							})
						}).catch(() => {
							ElNotification({
								title: '错误',
								message: '服务器错误！',
								type: 'error',
							})
						})
					})
			},

			openAddWin: function() {
				this.addDialog = true;
				this.title = '添加商户';
				this.btn = '添加';
				this.addBtn = true;
				this.editBtn = false;
				this.addData = {
					aly_product: "Dyvmsapi",
					aly_domain: "dyvmsapi.aliyuncs.com",
					aly_endpoint: "http://oss-cn-hangzhou.aliyuncs.com",
				}
			},

			txDL: function() {

			},

			addItem: function() {
				let that = this
				add(this.addData).then(res => {
					if (res.data.code == 200) {
						alert("添加成功")
						that.listData = []
						list(that.startPage, that.limit).then(res2 => {
							that.listData = res2.data.data
						})
					} else {
						alert("失败")
					}
				})
			},

			upDateItem: function() {
				let that = this
				update(this.addData).then(res => {
					if (res.data.data) {
						ElNotification({
							title: '成功',
							message: '更新成功！',
							type: 'success',
						})
						that.listData = []
						list(that.startPage, that.limit).then(res2 => {
							that.listData = res2.data.data
							that.addDialog = false
						})
					} else {
						alert("失败")
					}
				})
			},

			openWin: function(url) {
				window.open(url, '_blank')
			},

			pay: function(data) {
				const loading = ElLoading.service({
					lock: true,
					text: '正在检测...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				setTimeout(() => {

				}, 2000)
				payTest(data.client_id).then(res => {
					loading.close()
					let rs = JSON.parse(res.data.data)
					if (rs.code) {
						ElMessageBox.alert(rs.message, '提示', {
							confirmButtonText: 'OK',
						})
					} else {
						ElMessageBox.alert('该商户可以正常生成订单', '提示', {
							confirmButtonText: 'OK',
						})
					}
				}).catch(() => {
					loading.close()
					ElNotification({
						title: '错误',
						message: '服务器出现错误！',
						type: 'error',
					})
				})
			}
		}


	}
</script>

<style scoped="scoped">
	.search-box {
		margin: 0;
		border: 1px solid #e6e6e6;
		padding: 10px 20px 20px 20px;
		color: #6b6b6b;
	}

	.page {
		background-color: white;
		padding: 10px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.device-info-card {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: rgba(0, 0, 0, .5);
		z-index: 99;
	}

	.info-card {
		width: 800px;
		padding: 30px;
		position: absolute;
		top: 5vh;
		left: 50%;
		margin-left: -400px;
		transition: 1s;
		z-index: 2;
	}
</style>
